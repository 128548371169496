.mainbanner5 {
  .mainparent5 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #F6FCFF;
    .parent5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
    font-family: "f100";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
    font-family: "f100";
      line-height: normal;
      text-transform: uppercase;
    }
    .parent11 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
      max-width: 523px;
      width: 100%;
    }
    .parent111 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 50px;
    }
    .para3 {
      color: #000;
      font-size: 32px;
      font-style: normal;
    font-family: "f100";
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;
    }
    .para4 {
      color: #323232;
      font-size: 16px;
      font-style: normal;
    font-family: "f100";
      line-height: 100%; /* 16px */
    }
    .para5 {
      color: #000;
      font-size: 12px;
      font-style: normal;
    font-family: "f100";
      line-height: normal;
      text-transform: uppercase;
      padding: 19px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      border: unset;
      gap: 20px;
      margin-top: 40px;
    }
    .imgdiv {
      padding-left: 117px;
      padding-top: 91px;
      padding-right: 118px;
      padding-bottom: 74px;
      border-right: 1px solid #000;
    }
    .parent55 {
      display: flex;
    }
    .parent556 {
      padding-top: 90px;
      padding-bottom: 100px;
      margin: 0 auto;
    }
    .para11 {
      color: #000;
      font-size: 40px;
      font-style: normal;
    font-family: "f100";
      line-height: 100%; /* 40px */
      text-transform: uppercase;
    }
    .para12 {
      color: #333;
      font-size: 16px;
      font-style: normal;
    font-family: "f100";
      line-height: 100%; /* 16px */
      padding-top: 15px;
      width: 441px;
      padding-bottom: 30px;
    }
    .para13 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid #252525;
    }
    .para14 {
      color: #000;
      font-size: 18px;
      font-style: normal;
    font-family: "f100";
      line-height: 110%; /* 19.8px */
    }
    .para15 {
      color: #000;
      font-size: 18px;
      font-style: normal;
    font-family: "f100";
      line-height: 110%; /* 19.8px */
      text-transform: uppercase;
    }
    .para144 {
      color: #000;
      font-size: 24px;
      font-style: normal;
    font-family: "f200";
      line-height: 110%; /* 26.4px */
    }
  }
}
@media (max-width: 1200px) {
  .mainbanner5 {
    .mainparent5 {
      .parent55 {
        flex-direction: column;
      }
      .phonedivvv {
        display: block !important;
        padding: 0px 30px;
      }
      .displaydivv {
        display: none !important;
      }
      .para11 {
        font-size: 30px;
        padding-top: 50px;
        text-align: center;
      }
      .imgdiv{
        padding: 0px 23px;
        border-right: none;
        text-align: center;
      }
      .para12 {
        width: 100%;
        text-align: center;
      }
      .parent556{
        padding: 30px 30px 60px 30px;
        margin: unset;
      }
    }
  }
}
@media(max-width:600px){
  .mainbanner5 .mainparent5 .para11{
    font-family: "f200" !important;
  }
}