.mainbanner6 {
  .mainparent6 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #f6fcff;
    .parent6 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .parent11 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
      max-width: 523px;
      width: 100%;
    }
    .parent111 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 50px;
    }
    .para3 {
      color: #000;
      font-size: 32px;
      font-style: normal;
    font-family: "f300";
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;
    }
    .para4 {
      color: #323232;
      font-size: 16px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 16px */
    }
    .para5 {
      color: #000;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
      padding: 19px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      border: unset;
      gap: 20px;
      margin-top: 40px;
    }
    .headofroad {
      display: flex;
      // gap: 98px;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 700px;
      margin: 0 auto;
      width: 100%;
   
      position: relative;
      div{
        width: 100%;
        padding: 0px 50px;
      }
    }
    .headofroad2 {
      width: 362px;
    }
    .head1 {
      color: #000;
      text-align: center;
      font-size: 40px;
      font-style: normal;
    font-family: "f300";
      line-height: 100%; /* 40px */
      text-transform: uppercase;
      padding-top: 100px;
      padding-bottom: 146px;
    }
    .head2 {
      color: #000;
      font-size: 22px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 22px */
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    .head3 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "f100";
      line-height: 130%; /* 20.8px */
    }
    .head4 {
      color: #000;
      font-size: 22px;
      font-style: normal;
     font-family: "f100";
      line-height: 110%; /* 24.2px */
      text-transform: uppercase;
    }
    .head5 {
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-family: "f100";
      line-height: 130%; /* 20.8px */
      padding-top: 10px;
    }
    .head6 {
      color: #000;
      font-size: 22px;
      font-style: normal;
     font-family: "f200";
      line-height: 110%; /* 24.2px */
      text-transform: uppercase;
      padding-top: 40px;
    }
    .head7 {
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-family: "f100";
      line-height: 130%; /* 20.8px */
      padding-top: 10px;
    }
    .stickdivv {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .parent101 {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 91px;
      position: relative;
      max-width: 700px;
      margin: 0 auto;
      div{
        width: 100%;
        padding: 0px 50px;
      }
    }
    .paraaa1 {
      padding-left: 103px;
    }
    .para89 {
      color: #000;
      font-size: 22px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 22px */
      text-transform: uppercase;
    }
    .para80 {
      color: #000;
      font-size: 16px;
      font-style: normal;
     font-family: "f100";
      line-height: 130%; /* 20.8px */
      padding-top: 10px;
    }
    .para79 {
      color: #000;
      font-size: 22px;
      font-style: normal;
     font-family: "f100";
      line-height: 110%; /* 24.2px */
      text-transform: uppercase;
    }
    .para69 {
      color: #333;
      font-size: 16px;
      font-style: normal;
     font-family: "f100";
      line-height: 130%; /* 20.8px */
      padding-top: 10px;
    }
    .lastdivvvv {
      width: 362px;
    }
    .para1img1 {
      position: absolute;
      top: 62%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .para1img11 {
      position: absolute;
      top: 61%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .paraaa16 {
      padding-left: 79px;
    }
    .lastdivvvvt {
      width: 362px;
      padding-left: 30px;
    }
    .vybbb {
      padding-top: 40px;
    }
    .para1img111 {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .para1img1111 {
      position: absolute;
      top: 63%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .headoffroads {
      padding-bottom: 111px;
    }
    .textmiddd {
      color: #292929;
      text-align: center;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 12px */
      text-transform: uppercase;
      position: absolute;
      top: -35%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .textmidddd {
      color: #292929;
      text-align: center;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 12px */
      text-transform: uppercase;
      position: absolute;
      top: 28%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .textmiddddd {
      color: #292929;
      text-align: center;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: 100%; /* 12px */
      text-transform: uppercase;
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (max-width: 600px) {
  .mainbanner6 .mainparent6 .head1{
    padding: 50px 0px;
  }
  .mainbanner6 .mainparent6 .parent101{
    padding-top: 50px !important;
  }
  .mainbanner6 .mainparent6 .headofroad div{
    padding: 0px;
  }
  .mainbanner6 .mainparent6 .parent101 div{
    padding: 0px;
  }
  .mainbanner6 .mainparent6 .para89{
    padding-top: 20px;
  }
  .mainbanner6 {
    .mainparent6 {
      .head1{
        font-size: 30px;

      }
      .headofroad{
        flex-direction: column;
        padding: 0px 34px;
        text-align: center;
        gap: 30px;

      }
      .stickdivv{
        display: none !important;
      }
      .para1img1{
        display: none !important;
      }
      .para1img11{
        display: none !important;
      }
      .para1img111{
        display: none !important;

      }
      .para1img1111{
        display: none !important;

      }
      .headofroad2{
        width: 100%;
      }
      .parent101{
        flex-direction: column;
        padding: 0px 34px;
        text-align: center;
        padding-top: 103px;
        gap: 30px;
      }
      .paraaa1{
        padding-left: 0px;
      }
      .lastdivvvv{
        width: 100%;
      }
      .paraaa16{
        padding-left: 0px;
      }
      .lastdivvvvt{
        padding-left: 0px;
        width: 100%;
      }
      .textmiddd{
        display: none !important;
      }
      .textmidddd{
        display: none !important;

      }
      .textmiddddd{
        display: none !important;

      }
      .textmiddddd{
        display: none !important;

      }
      .monthdiv{
        display: block !important;
        color: #000;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 12px */
text-transform: uppercase;
padding-top: 20px;
padding-bottom: 20px;
      }
      .warrry{
        padding-top: 20px;
      }
      .phonestickkk{
        display: block !important;
        margin: 0 auto;
      }
  
    }
    }
}
@media(max-width:1439px) {
  .mainbanner6 {
    .mainparent6 {
      .headoffroads {
        padding: 0px 20px 80px 20px;
      }
    }
    }
}