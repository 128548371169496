.mainbanner4 {
  .mainparent4 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #fff;

    .parent4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }

    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }

    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }

    .parent11 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 28px;
      max-width: 523px;
      width: 100%;
    }

    .parent111 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 50px;
    }

    .para3 {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-family: "f300";
      line-height: 120%;
      /* 38.4px */
      text-transform: uppercase;
    }

    .para4 {
      color: #323232;
      font-size: 16px;
      font-style: normal;
      font-family: "f200";
      line-height: 100%;
      /* 16px */
    }

    .para5 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
      padding: 19px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      border: unset;
      gap: 20px;
      margin-top: 40px;
    }

    .card1 {
      border-radius: 15px;
      border: 1px solid #e6e6e6;
      background: #fff;
      padding: 25px 53px 45px 25px;
      // width: 335px;
      max-width: 378px;
      width: 100%;
      height: 445px;
    }

    .para6 {
      padding-bottom: 124px;
      color: #808080;
      font-size: 20px;
      font-style: normal;
      font-family: "f200";
      line-height: 100%;
      /* 20px */
      text-transform: uppercase;
    }

    .para7 {
      color: #000;
      font-size: 23px;
      font-style: normal;
      font-family: "f100";
      line-height: 110%;
      /* 25.3px */
      text-transform: uppercase;
      padding-top: 35px;
      padding-bottom: 20px;
    }

    .para8 {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-family: "f100";
      line-height: 130%;
      /* 18.2px */
    }

    .cardshead {
      display: flex;
      gap: 20px;
      margin: 109px 0px 104px 45px;
    }
  }
}

@media(max-width:600px) {
  .mainbanner4 {
    .mainparent4 {
      .card1 {
        padding: 25px 20px 45px 25px;
        width: 285px;
        height: 372px;
      }

      .cardshead {
        margin: 65px 0px 65px 20px;
      }

      .para6 {
        padding-bottom: 60px;
      }
    }
  }
}