.mainbanner9 {
  .mainparent9 {
    border-radius: 0px 0px 15px 15px;
    border: 1px solid #000;
    background: #f6fcff;
    border-top: unset;
    position: relative;
    .parent9 {
      max-width: 580px;
      padding: 42px 0px 41px 123px;
    }
    .para111 {
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-family: "f300";
      line-height: 120%; /* 60px */
      text-transform: uppercase;
    }
    .para112 {
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-family: "f100";
      line-height: 100%; /* 16px */
      padding-top: 15px;
      padding-bottom: 40px;
    }
    .para113 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
      border: unset;
      padding: 19px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      gap: 20px;
      width: 189px;
      justify-content: center;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: scale(1.1);
        border: none;
        font-family: "f200";
      }
    }
    .lower {
      position: absolute;
      bottom: 0px;
      width: 100%;
    }
    .circleee {
      position: absolute;
      right: 0px;
    }
  }
}
@media (max-width: 1200px) {
  .mainbanner9 {
    .mainparent9 {
      .circleee {
        display: none !important;
      }
      .para111 {
        font-size: 32px;
      }
      .parent9 {
        padding: 56px 30px 248px 30px;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
      }
      .lowerrrr {
        display: block !important;
        position: absolute;
        bottom: 0px;
        right: 0px;
        border-radius: 20px;
      }
    }
  }
}
@media (max-width: 600px) {
  .lower {
    border-radius: 20px;
    flex-shrink: 0;
    bottom: 0;
    height: 5rem;
  }
  .mainbanner9 .mainparent9 .para111 {
    font-family: "f200" !important;
  }
}
