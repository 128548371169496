.mainbanner8 {
  .mainparent8 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #fff;
    .parent8 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .parentbar111 {
      padding-top: 90px;
      padding-bottom: 116px;
    }

    .firstdiv {
      color: unset;
      background-color: unset;
      box-shadow: unset;
      border-radius: unset;
      padding: 25px;
      border-radius: 15px;
      border: 1px solid #e6e6e6;
      background: #fff;
      backdrop-filter: blur(2px);
      padding: 25px;
      margin-bottom: 10px;
    }
    .secondiv {
      --bs-accordion-border-width: none;
      --bs-accordion-bg: none;
      padding: 60px 80px 0px 80px;
    }
    .thirddiv {
    }
    .textdivv {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      padding-left: 40px;
    }
    .textdiv2 {
      color: #000;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
    }
    .accordion-body {
      color: #d7d7d7;
      font-size: 14px;
      font-style: normal;
      font-family: "f100";
      line-height: 140%; /* 19.6px */
    }

    .accordion-button::after {
      background-image: url(../../../assets/add.png);
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url(../../../assets/minus.png);
    }
    .para444444 {
      color: #000;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-family: "f300";
      line-height: 100%; /* 40px */
      text-transform: uppercase;
    }
    .parentbar111 {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    
  }
  .mainfaqs {
    // min-height: 100vh;
    background: #fff;
    .innerfaq {
      max-width: 1066px;
      margin: 0 auto;
      width: 100%;
      padding-top: 80px;
      // padding-bottom: 110px;
      .accordion {
        .active {
          // background: #ECECEC !important;
          border-radius: 15px;
border: 1px solid #000 !important; 

background: #FFF;

backdrop-filter: blur(2px);
        }
        .accordion-item {
          border-radius: 15px;
          border: 1px solid #E6E6E6;
          
          background: #FFF;
          
          // backdrop-filter: blur(2px);
          margin-bottom: 10px;
          .accordion-header {
            border-bottom: none !important;
            padding: 0px;
            .accordion-button:not(.collapsed)::after {
              // background: url("../../../src/assets/minus.svg");
              background-repeat: no-repeat;
              background-position: unset;
              background-size: cover;
              margin: 0;
              padding: 0;
              width: 24px;
              height: 24px;
            }
            .accordion-button::after {
              // background: url("../../../src/assets/plus.svg");
              background-repeat: no-repeat;
              background-position: unset;
              background-size: cover;
              margin: 0;
              padding: 0;
              width: 24px;
              height: 24px;
            }
            .accordion-button {
              border-radius: 10px;
              box-shadow: none !important;
              padding: 0px;
              display: flex;
              align-items: center;
              background: transparent;
              border: none;
              border-radius: 0px;
              outline: none;
              justify-content: space-between;
              padding: 25px;
              p {
                color: #000;

                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-family: "f200";
                line-height: normal;
                display: flex;
               
                justify-content: flex-start;
                gap: 18px;
                span {
                  color: #000;
                  font-weight: 500px;
                }
              }
            }
          }
        }
        .accordion-body {
          padding: 0px 86px 25px 86px;
          .para {
            color: #333;
            font-size: 16px;
            font-style: normal;
            font-family: "f100";
            line-height: 140%;
            max-width:  712.627px;
            width: 100%;
          }
        }
      }
    }
  }
}


@media (max-width: 1200px) {
  .mainbanner8 {
    .mainparent8 {
      .para444444 {
        font-size: 30px;
        text-align: center;
      }
      .secondiv {
        padding: 40px 20px 0px 20px;
      }
      .textdivv {
        padding-left: 10px;
        font-size: 16px;
      }
      .firstdiv {
        padding: 16px;
      }
      .textdiv2 {
        font-size: 16px;
      }
    }

    .mainfaqs{
      padding: 23px;
      p{
        font-size: 16px !important;
        gap: 10px !important;
        text-align: unset !important;

      }
    }
  }
}
@media(max-width:600px){
  .mainbanner8 .mainfaqs .innerfaq .accordion .accordion-item .accordion-header .accordion-button{
    padding: 16px;
  }
  .mainbanner8 .mainfaqs .innerfaq .accordion .accordion-body{
    padding: 0px 16px 16px 16px;
  }
  .mainbanner8 .mainparent8 .parentbar111{
    padding-bottom: 70px !important;
  }
  .mainbanner8 .mainfaqs .innerfaq{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .mainbanner8 .mainfaqs{
    padding-bottom: 0px !important;
    padding-top: 70px;
  }
  .mainbanner8 .mainparent8 .para444444{
    font-family: "f200" !important;
  }
}
