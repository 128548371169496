.mainbanner7 {
  .mainparent7 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    background: #fff;
    .parent7 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para11 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 71px 120px 43px 120px;
    }
    .para12 {
      color: #000;
      font-size: 26px;
      font-style: normal;
      font-family: "f300";
      line-height: 120%; /* 31.2px */
      text-transform: uppercase;
    }
    .para13 {
      color: #000;
      font-size: 12px;
      font-style: normal;
     font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 15px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      border: unset;
    }
    .card11 {
      border-radius: 15px;
      border: 1px solid #E6E6E6;
      background: #fff;
      padding-bottom: 26px;
      width: 350.67px;
      &:hover{
        border: 1px solid #000 !important;
        background: #fff;
      }
     
    }
    .imgcarddd {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    .div1 {
      padding: 28px 26px 0px 26px;
    }
    .para4 {
      color: #000;
      font-size: 18px;
      font-style: normal;
     font-family: "f200";
      line-height: 110%; /* 19.8px */
      padding-bottom: 15px;
    }
    .para5 {
      color: #000;
      font-size: 14px;
      font-style: normal;
     font-family: "f300";
      line-height: 110%; /* 15.4px */
      text-transform: uppercase;
    }
    .para6 {
      color: #8b8b8b;
      font-size: 14px;
      font-style: normal;
     font-family: "f200";
      line-height: 110%; /* 15.4px */
      text-transform: uppercase;
    }
    .para7 {
      color: #333;
      font-size: 14px;
      font-style: normal;
     font-family: "f100";
      line-height: 110%; /* 15.4px */
      text-transform: uppercase;
    }
    .maintab {
      display: flex;
      align-items: center;
      gap: 10px;
      border-top: 1px solid #81d4fa;
      padding-top: 15px;
    }
    .headofcards {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 97px;
    }
  }
}
@media (max-width: 1160px) {
  .mainbanner7 {
    .mainparent7 {
    .para13{
      display: block !important;
      width: 100%;
      margin-bottom: 41px;
    }
    .vyyyyy{
      display: none !important;
    }
    .para11{
      padding: 50px;
      text-align: center;
    }
    .headofcards{
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0px 30px;
    }
    .butdivmain{
      padding: 0px 30px;
    }
    .card11{
      width: 100%;
    }
    .imgcarddd{
      width: 100%;
    }
    }
    }
}
@media(max-width:600px){
  .mainbanner7 .mainparent7 .para12{
    font-family: "f200" !important;
    white-space: nowrap;
  }
  .mainbanner7 .mainparent7 .para4{
    font-family: "f100" !important;
  }
  .mainbanner7 .mainparent7 .para5{
    font-family: "f100" !important;
  }
}