.mainbanner2 {
  .mainparent2 {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;

    background: #f6fcff;
    .parent2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #000;
      padding: 19px 0px 20px 0px;
      margin: 0px 40px;
    }
    .para1 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
    }
    .para2 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f100";
      line-height: normal;
      text-transform: uppercase;
    }
    .parent11 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      max-width: 523px;
      width: 100%;
    }
    .parent111 {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 50px;
    }
    .para3 {
      color: #000;
      font-size: 32px;
      font-style: normal;
      font-family: "f300";
      line-height: 120%; /* 38.4px */
      text-transform: uppercase;
    }
    .para4 {
      color: #323232;
      font-size: 16px;
      font-style: normal;
      font-family: "f100";
      line-height: 100%; /* 16px */
    }
    .para5 {
      color: #000;
      font-size: 12px;
      font-style: normal;
      font-family: "f200";
      line-height: normal;
      text-transform: uppercase;
      padding: 19px 25px;
      border-radius: 110px;
      background: #81d4fa;
      backdrop-filter: blur(5px);
      display: flex;
      align-items: center;
      border: unset;
      gap: 20px;
      transition: .3s ease-in-out;
      &:hover{
       transform: scale(1.1);
       border: none;
       font-family: "f200";
      }
    }
  }
}
@media(max-width:800px) {
  .mainbanner2 {
    .mainparent2 {
.parent111{
  flex-direction: column-reverse;
}
.para3{
  font-size: 26px;

}
.parent11{
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 30px;
}
.img2divv{
  padding: 0px 23px;
}
    }
  }
}
@media(max-width:1439px) {
  .mainbanner2 {
    .mainparent2 {
    .parent111{
      padding: 30px;
    }
    }
    }
}
@media(max-width:600px){
  .mainbanner2 .mainparent2 .para3{
    width: 343px;
  }
  .mainbanner2 .mainparent2 .para4{
    width: 343px;
  }
}