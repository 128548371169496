.mainbanner {
  background-image: url("../../../../public/assets/Bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .mainparent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 107px;
    border: 1px solid #000;
    border-top: none;

    .innercontent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h2 {
        color: #000;
        text-align: center;
        font-size: 45px;
        font-style: normal;
        font-family: "f300";
        line-height: 100%; /* 45px */
        text-transform: uppercase;
        max-width: 685px;
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
      }
      p {
        color: #393939;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-family: "f100";
        line-height: 100%;
        max-width: 445px;
        width: 100%;
      }
      .learnbutttt {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-family: "f200";
        line-height: normal;
        text-transform: uppercase;
        padding: 19px 25px;
        border-radius: 110px;
        background: #81d4fa;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        gap: 20px;
        white-space: nowrap;
        width: 189px;
        border: none;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
          border: none;
          font-family: "f200";
        }
      }
      .readbutttt {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-family: "f200";
        line-height: normal;
        text-transform: uppercase;
        padding: 19px 25px;
        border-radius: 110px;
        border: 2px solid #81d4fa;
        background: #fff;
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        gap: 20px;
        width: 189px;
        justify-content: center;

        white-space: nowrap;
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1.1);
          font-family: "f200";
        }
      }
      .buttonshead {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 43px;
        margin-bottom: 171px;
      }
    }
  }
}
@media (max-width: 600px) {
  .mainbanner {
    .mainparent {
      .buttonshead {
        flex-direction: column;
        width: 100%;
      }
      .innercontent {
        padding: 0px 30px 0px 30px;
      }
      .learnbutttt {
        width: 100% !important;
      }
      .readbutttt {
        width: 100% !important;
      }
    }
  }
  .mainbanner .mainparent .innercontent p {
    width: 343px;
  }
}
