.main-navbar {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-bottom: 1px solid #000;
    background: #FFF;
    backdrop-filter: blur(25px);
    border-radius: 15px 15px 0px 0px;
    // padding: 20px 18px;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #000;
    .navbar {
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.08);
        border-left: 1px solid rgba(255, 255, 255, 0.08);
        padding: 0;
        padding: 20px 18px;
      

        .navbar-brand {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px;
            padding: 0px;
        }

        .navbar-nav {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            gap: 20px;
            align-items: center;
            position: relative;
            a{
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
            }

            .menubtn {
                position: absolute;
                right: -64px;
                height: 64px;
                width: 64px;
                display: flex;
                justify-content: center;
                align-items: center;

                .menubtnimg {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                }
            }

            .signupbtn {
                background: #3BD6FF;
                display: flex;
                width: 144px;
                height: 64px;
                justify-content: center;
                align-items: center;
                gap: 7px;
                border: none;
                color: #001838;
                font-family: "c100";
                font-size: 14px;
                font-style: normal;
              font-family: "f100";
                line-height: normal;

                &:hover{
                    background-color: #FFF;
                    transition: 0.7s;
                }
            }
        }
    }
}

.mainmenu {
    background: #001838;
    padding: 0px;
    height: 100vh;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    overflow: hidden;

    .offcanvas-header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px;

        .menuheadleft {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 50px;

            .box {
                border-right: 1px solid rgba(255, 255, 255, 0.08);
                width: 64px;
                height: 64px;
                display: block;
            }
        }

        .menuheadright {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .signupbtn {
                background: #3BD6FF;
                display: flex;
                width: 144px;
                height: 64px;
                justify-content: center;
                align-items: center;
                gap: 7px;
                border: none;
                color: #001838;
                font-family: "f100";
                font-size: 14px;
                font-style: normal;
              font-family: "f100";
                line-height: normal;
                &:hover{
                    background-color: #FFF;
                    transition: 0.7s;
                }
            }

            .box {
                border-left: 1px solid rgba(255, 255, 255, 0.08);
                width: 64px;
                height: 64px;
                display: block;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }

    .offcanvas-body {
        padding: 0px;
        overflow: hidden;

        .menucards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 12px;
            height: calc(100% - 80px);
            margin: 10px 64px;

            .menuinnercard {
                border: 1px solid rgba(255, 255, 255, 0.08);
                backdrop-filter: blur(10px);
                display: flex;
                padding: 43px 30px;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
                cursor: pointer;

                &:hover {
                    border: 1px solid #3BD6FF;
                    background: linear-gradient(180deg, rgba(0, 24, 56, 0.10) 0%, rgba(59, 214, 255, 0.10) 100%);
                    transition: 0.7s;
                }

                .menucardpara {
                    color: #FFF;
                    font-family: "c100";
                    font-size: 22px;
                    font-style: normal;
                   font-family:" f100";
                    line-height: 110%;
                    text-transform: uppercase;
                }
            }
        }

        .menufooter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid rgba(255, 255, 255, 0.08);
            backdrop-filter: blur(10px);

            .menufooterleft {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 50px;

                .box {
                    border-right: 1px solid rgba(255, 255, 255, 0.08);
                    width: 64px;
                    height: 64px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .menufootpara {
                    color: #FFF;
                    font-family: "c100";
                    font-size: 14px;
                    font-style: normal;
                  font-family: "f100";
                    line-height: 110%;
                }
            }

            .menufooterright {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 50px;

                .box {
                    border-left: 1px solid rgba(255, 255, 255, 0.08);
                    width: 64px;
                    height: 64px;
                    display: block;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .socialicons {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;


                    a {
                        margin-right: 15px;

                        svg {

                            path {
                                stroke: #FFF;
                            }
                        }

                        &:hover {
                            svg {
                                path {
                                    stroke: #3BD6FF;
                                    transition: 0.7s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:1199px) {
    .main-navbar .navbar {
        border: none;
        padding: 0px;
        padding-left: 20px;

    }
    .navbar {
        
      
    }

    .main-navbar .navbar .navbar-nav {
        display: none;
    }
}

@media(max-width:1199px) {
    .mblbtn {
        display: block !important;
        background: transparent;

        .btnss {
            background: transparent;
            border: none;
            border-left: 1px solid rgba(255, 255, 255, 0.08);
            padding: 20px;
            border-left: 1px solid #000;
        }
    }

    .mblmenu {
        background: #FFF;


        backdrop-filter: blur(10px);
        padding: 0px;
        min-height: 95vh;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        overflow: hidden;

        .offcanvas-header {
            padding: 20px 30px;
            border-bottom: 1px solid #000;

        }

        .offcanvas-header .btn-close {
            // background: url(../../../../src/assets/btnclose.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: contain;
            width: 22px;
            height: 22px;
            box-shadow: none !important;
            opacity: unset;
            position: absolute;
            padding: 0px;
            margin: 0px;
            top: 29px;
            right: 31px;
        }

        .offcanvas-body {
            padding: 0px;
            position: relative;

            .innerfooter {
                position: absolute;
                bottom: -419px;
                left: 50%;
                transform: translate(-50%, 0%);
                
                padding-bottom: 41px;
                .socialsmain {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;

                    .socialinner {
                        svg path {
                           
                        }

                        &:hover {
                            background: #031B3C;
                            transition: 0.3s;
                            cursor: pointer;

                            .socialpara {
                                color: #3BD6FF;
                                transition: 0.3s;
                            }

                            svg path {
                                stroke: #3BD6FF;
                                transition: 0.3s;
                            }
                        }
                    }
                }


            }

            .maintext {
                .para {
                    border-bottom: 1px solid #D2D2D2;                    color: #FFF;
                    padding: 24px 30px;

                    font-family: "c100";
                    font-size: 18px;
                    font-style: normal;
                  font-family: "f100";
                    width: 100%;
                    line-height: normal;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: #000;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
                }


            }
        }
    }
}