.mainfooter {
.footerinner{
  padding: 30px 30px 30.119px 30px;
  border-radius: 15px;
  border: 1px solid #000;
  background: #FFF;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .innnerf{
    display: flex;
  }
  .textdiv{
    display: flex;
    align-items: center;
    gap: 25px;
  }
  .textdiv2{
    display: flex;
    align-items: center;
    gap: 22px;
  }
  .para66{
    color: #000;
font-size: 14px;
font-style: normal;
font-family: "f200";
line-height: normal;
padding-right: 25px;
  }
  .para666{
    color: #000;
font-size: 14px;
font-style: normal;
font-family: "f200";
line-height: normal;
padding-left: 25px;
text-transform: capitalize;
  }
  .para6666{
    color: #000;
font-size: 14px;
font-style: normal;
font-family: "f200";
line-height: normal;
text-transform: capitalize;
  }
  .proooo{
    display: flex;
    align-items: center;
  }
}
}
@media (max-width: 960px) {
  .mainfooter {
    .footerinner{
    flex-direction: column;
    gap: 33px;
    padding: 15px;
    .innnerf{
      flex-direction: column-reverse;
    }
    .para666{
      padding-left: 0px;
      white-space: nowrap;
    }
    .textdiv{
      gap: 20px;
      padding-bottom: 20px;
      border-bottom:  1px solid #000;
    }
    .reservedimg{
      display:  none !important;
    }
    .para66{
      padding-right: 0px;
     padding-top: 20px;
    }
    .proooo{
      justify-content: center;
    }
    }
    }
}
@media(max-width:600px){
  .mainfooter {
    .footerinner{
    flex-direction: column;
    gap: 33px;
    padding: 15px;
    .innnerf{
      flex-direction: column-reverse;
      width: 100%;
    }
  }
}
.mainfooter .footerinner .textdiv{
  gap: 8px;
  justify-content: center;
  width: 100%;
}
}